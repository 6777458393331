import React, { useEffect } from 'react'
import Cards from './Cards'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Player from './Player'
import Channel from './Channel'



export default function Content() {



 
  return (
    <div className='w-[100%] h-auto bg-black flex justify-center'>
      <BrowserRouter>
      <Routes>
<Route path='/' element={<Cards/>}/>
<Route path='/Player' element={<Player/>}/>
<Route path='/Channel' element={<Channel/>}/>
      
      
      </Routes>
      </BrowserRouter>
    </div>
  )
}

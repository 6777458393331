import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { dispatch } from "redux";
import { ADD } from "../Redux/Actions/Action";
import { Link } from "react-router-dom";

export default function Channel() {
    const [task, settask]=useState([])
  
    const dispatch = useDispatch();
    const send = (e) => {
      dispatch(ADD(e));
    };
   
  
    const getdata = useSelector((state) => state.CartRedcuer.Carts);
  
   
    useEffect(()=>{
        let key = "AIzaSyBoK1LTaNq1_YMbnwzrPqA6w8i2zlAKvvU";
        let key3 = "AIzaSyCCqbYDheA88yVq8p4_U15Zq2TpwCP-fiI";
        let key4 = "AIzaSyCrxJPi4ifUJGMZeed6j9LbCdzoJ5cqQ6g";
        let key5 = "AIzaSyBnd59EgnGzxOyJQcnbOa62y31ThXgIrqw";
        let key6 = "AIzaSyCqi7wytSFpgtonBVl5Dqtf8kGrlbWV630";
       let key7 =  'AIzaSyBzA02NJKQWDqm0E3TMPIVSU7gEW_zvR7E'
       let key8 =  'AIzaSyBkFBUQnTSWiFH0gPmlqIJ1dRf02iexPuo'
    
        localStorage.removeItem('key')
      
        
      const API = `https://youtube.googleapis.com/youtube/v3/activities?part=snippet%2CcontentDetails&channelId=${getdata[0].snippet.channelId}&maxResults=50&key=${key4}`;
    fetch(API).then((res)=>res.json()).then((resjson)=>{
      const Data = resjson.items;
     settask(Data)
    
    })
    },[])
  return (
    <div className="w-[100%] h-auto bg-black flex flex-col">
      <div className="w-[100%] h-[50vh]  bg-[#252525] mt-4 flex flex-col">
        <img
          className="w-[100%] h-[30vh]"
          src={getdata[0].snippet.thumbnails.high.url}
          alt=""
        />
        <div className="w-[100%] h-[20vh]  flex">
          <div className="w-[50%] h-[20vh] flex justify-center items-center ">
          <svg
                className="ml-10"
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
              <h1 className="text-3xl font-bold ml-10 text-white">{getdata[0].snippet.channelTitle}</h1>
          </div>
          <div  className="w-[50%] h-[20vh] flex justify-center items-center " >
          <a
            onClick={() => {
                let subs = document.getElementById("subs");

                if (subs.innerText == "Subscribe") {
                  subs.innerText = "Subscribed";
                } else {
                  subs.innerText = "Subscribe";
                }
                toast("Subscribed Successfully");
              }}
                
                  class="relative inline-flex ml-4 items-center px-12 text-center py-2 overflow-hidden text-lg font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-white cursor-pointer"
                >
                  <span class="absolute left-0 block w-full h-0 transition-all  bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease text-center"></span>

                  <span class="absolute right-0 flex items-center justify-start text-center w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease"></span>
                  <span class="relative" id="subs">
                    Subscribe
                  </span>
                </a>
          <a
               onClick={() => {
                let subs = document.getElementById("subs1");

                if (subs.innerText == "Join") {
                  subs.innerText = "Joined";
                } else {
                  subs.innerText = "Join";
                }
                toast("Joined Successfully");
              }}  
                  class="relative inline-flex ml-4 items-center px-6 py-2 overflow-hidden text-lg font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-white cursor-pointer"
                >
                  <span class="absolute left-0 block w-full h-0 transition-all  bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

                  <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease"></span>
                  <span class="relative" id="subs1">
                   Join
                  </span>
                </a>
          </div>
        </div>
      </div>
      <div className="w-[100%] h-auto mt-10 bg-black">
      <ul className="grid grid-cols-1 lg:grid-cols-3 h-auto">
    
    {
  task.map((product) => (



      <div
        className="w-[40vh] shadow-2xl  mb-5   h-[45vh] max-w-sm bg-[#252525]   rounded-2xl    ml-10 mt-24 "
       
      >
        <a >
        

            <Link to={'/Player'}>
          <img
            className="h-72 w-[100%]  rounded-2xl cursor-pointer hover:rounded-none bg-gray-100" id="image" onClick={() => {
              send(product)
              
            }
          }
          src={product.snippet.thumbnails.high.url}
          alt="product image"
          />
          </Link>
       
        </a>
        <div className="px-5 mt-6 pb-5">
          <a
           onClick={() => {
            send(product)
            
          }
        } >
          <Link to={'/Player'}>
            <h5 className="text-white text-start font-semibold px-2 mt-4">
              {product.snippet.title}
            </h5>
            <h5 className="text-white text-start font-semibold px-2 mt-4">
              {product.snippet.channelTitle}
            </h5>
          </Link>
          </a>
          
         <div>
          {/* <span>
            {product.description}
          </span> */}
         </div>
         
        </div>
        
      </div>
    ))}
</ul>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

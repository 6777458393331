import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { dispatch } from "redux";
import { ADD } from "../Redux/Actions/Action";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
export default function Player() {
  const [task, settask]=useState([])
  
  const dispatch = useDispatch();
  const send = (e) => {
    dispatch(ADD(e));
  };

  const getdata = useSelector((state) => state.CartRedcuer.Carts);
 


  let key7 =  'AIzaSyBzA02NJKQWDqm0E3TMPIVSU7gEW_zvR7E'
  let link = `https://www.youtube.com/embed/${getdata[0].id.videoId}`;
  let share = `https://www.youtube.com/watch?v=/${getdata[0].id.videoId}`;

  


  var KEY = JSON.parse(localStorage.getItem('key'))
  var keyword = 'MOST POPULAR'
  var value = KEY
  
  if(value== null){
    value=keyword;
  }
  var item = getdata[0].snippet.title;
  

  useEffect(()=>{
    let key = "AIzaSyBoK1LTaNq1_YMbnwzrPqA6w8i2zlAKvvU";
    let key3 = "AIzaSyCCqbYDheA88yVq8p4_U15Zq2TpwCP-fiI";
    let key4 = "AIzaSyCrxJPi4ifUJGMZeed6j9LbCdzoJ5cqQ6g";
    let key5 = "AIzaSyBnd59EgnGzxOyJQcnbOa62y31ThXgIrqw";
    let key6 = "AIzaSyCqi7wytSFpgtonBVl5Dqtf8kGrlbWV630";
    let key7 =  'AIzaSyBzA02NJKQWDqm0E3TMPIVSU7gEW_zvR7E'
    let key8 =  'AIzaSyBkFBUQnTSWiFH0gPmlqIJ1dRf02iexPuo'

    localStorage.removeItem('key')
  
 
    
  const API = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&q=${getdata[0].snippet.title}&key=${key}`;
fetch(API).then((res)=>res.json()).then((resjson)=>{
  const Data = resjson.items;
  settask(Data)
  
})

  },[])


  return (
    <div className="w-[100%] h-[93vh] mt-[7vh] bg-black flex-col flex lg:flex-row justify-between ">
      <div className="w-[100%] h-auto bg-black flex flex-col">
        <iframe
          src={link}
          frameborder="0"
          className="w-[100%] h-[50vh] ml-1 rounded-lg  "
        ></iframe>
        <div className="w-[100%] h-[45vh] bg-orange-500 mt-4">
          <div className="w-[100%] h-[970vh] bg-black flex flex-col justify-start items-start">
            <h1 className="text-white text-xl font-bold ml-10 mt-6">
              {getdata[0].snippet.title}
            </h1>
            <h1 className="text-white text-xl font-bold ml-10 mt-6">

            </h1>
            <div className=" text-white w-[100%] h-[7vh] flex items-center justify-center ">
              <svg
                className=""
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
              <div className="flex justify-between items-center ml-3">
                <Link to={'/Channel'}>
                <h1 className="text-white text-lg font-bold mr-5 cursor-pointer "
                onClick={()=>{
               
                }}>
                  {getdata[0].snippet.channelTitle}
                </h1>
                  </Link>

                <a
                  onClick={() => {
                    let subs = document.getElementById("subs");

                    if (subs.innerText == "Subscribe") {
                      subs.innerText = "Subscribed";
                    } else {
                      subs.innerText = "Subscribe";
                    }
                    toast("Subscribed Successfully");
                  }}
                  class="relative inline-flex ml-4 items-center px-3 py-2 overflow-hidden text-lg font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-white cursor-pointer"
                >
                  <span class="absolute left-0 block w-full h-0 transition-all  bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>

                  <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease"></span>
                  <span class="relative" id="subs">
                    Subscribe
                  </span>
                </a>
              </div>
              <div className="ml-20 flex justify-between">
                <a
                  class="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded-3xl inline-flex items-center cursor-pointer"
                  onClick={() => {
                    toast("Liked Video");
                  }}
                >
                  Like
                </a>
                <a
                  class="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded-3xl ml-2 inline-flex items-center cursor-pointer"
                  onClick={() => {
                    toast("Disliked Video");
                  }}
                >
                  DisLike
                </a>
                <a
                  class="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4  inline-flex items-center ml-10 rounded-3xl cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    toast("Link Copied Successfully");
                  }}
                >
                  Share
                </a>
              </div>
            </div>
        <div className="w-[95%] ml-6 rounded-2xl h-[10vh] bg-gray-600 flex flex-col mt-5">
          <h1 className="font-bold text-white text-lg">Description</h1>
          <h1 className="px-9 text-white">{getdata[0].snippet.description}</h1>
        </div>
          </div>
        </div>
      <div className="w-[100%] h-auto flex  flex-col">
      
    
      
      </div>
      </div>







      <div className="w-[100%] lg:w-[29%] h-auto ">
      <ul className="flex flex-col w-[100%]">
    
    {
    task.map((product) => (
<div className="w-[100%] h-auto bg-black  flex  items-center border-2 rounded-2xl border-amber-500">
<div className="w-[60%]  h-[20vh] flex justify-center items-center">
  <img onClick={() => {
                    send(product)
                    
                  }
                } className="h-[17vh] " src={product.snippet.thumbnails.high.url} alt="" />
</div>
<div className="w-[60%] h-[20vh] flex justify-evenly items-center flex-col">
  <h1 className="text-sm font-bold text-white ml-4">{product.snippet.title}</h1>
  <h1 className="text-sm font-bold text-white ">{product.snippet.channelTitle}</h1>
</div>













</div>


    
    ))}
</ul>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

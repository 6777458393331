import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispatch} from 'redux'
import { ADD } from "../Redux/Actions/Action";
import { Link } from "react-router-dom";
export default function Cards() {
  const dispatch = useDispatch();
 
  const getdata = useSelector((state) => state.CartRedcuer.Carts);
  const [task, settask] = useState([]);
  const send = (e) => {
    dispatch(ADD(e));
  };
  let key2 = "AIzaSyCrxJPi4ifUJGMZeed6j9LbCdzoJ5cqQ6g";
  
    let key = "AIzaSyBoK1LTaNq1_YMbnwzrPqA6w8i2zlAKvvU";
    let key3 = "AIzaSyCCqbYDheA88yVq8p4_U15Zq2TpwCP-fiI";
    let key4 = "AIzaSyCrxJPi4ifUJGMZeed6j9LbCdzoJ5cqQ6g";
    let key5 = "AIzaSyBnd59EgnGzxOyJQcnbOa62y31ThXgIrqw";

   

   
  
  let id = 'UCGwcH4qnJ2qM_ZJUSFcAMAA'
 let channelid=`https://www.youtube.com/channel/${id}`

  


  var KEY = JSON.parse(localStorage.getItem('key'))
var keyword = 'gaming'
var value = KEY

if(value== null){
  value=keyword;
}

  useEffect(()=>{
    let key = "AIzaSyBoK1LTaNq1_YMbnwzrPqA6w8i2zlAKvvU";
    let key3 = "AIzaSyCCqbYDheA88yVq8p4_U15Zq2TpwCP-fiI";
    let key4 = "AIzaSyCrxJPi4ifUJGMZeed6j9LbCdzoJ5cqQ6g";
    let key5 = "AIzaSyBnd59EgnGzxOyJQcnbOa62y31ThXgIrqw";
    let key6 = "AIzaSyCqi7wytSFpgtonBVl5Dqtf8kGrlbWV630";
   let key7 =  'AIzaSyBzA02NJKQWDqm0E3TMPIVSU7gEW_zvR7E'
   let key8 =  'AIzaSyBkFBUQnTSWiFH0gPmlqIJ1dRf02iexPuo'

    localStorage.removeItem('key')
  
    
  const API = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&q=${value}&key=${key}`;
fetch(API).then((res)=>res.json()).then((resjson)=>{
  const Data = resjson.items;
 settask(Data)
})
},[])


  return (
    <div className="w-[100%] h-[1000vh]   bg-black flex flex-row ">

    <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 h-96">
    
          {
        task.map((product) => (



            <div
              className="w-[40vh] shadow-2xl     h-[45vh] max-w-sm bg-[#252525]   rounded-2xl ml-14 mt-24 "
             
            >
              <a >
              

                  <Link to={'/Player'}>
                <img
                  className="h-72 w-[100%]  rounded-2xl cursor-pointer hover:rounded-none bg-gray-100" id="image" onClick={() => {
                    send(product)
                    
                  }
                }
                src={product.snippet.thumbnails.high.url}
                alt="product image"
                />
                </Link>
             
              </a>
              <div className="px-5 mt-6 pb-5">
                <a
                 onClick={() => {
                  send(product)
                  
                }
              } >
                <Link to={'/Player'}>
                  <h5 className="text-white text-start font-semibold px-2 mt-4">
                    {product.snippet.title}
                  </h5>
                </Link>
                  <Link to={'/Channel'}>
                  <h5 className="text-white text-start font-semibold px-2 mt-4">
                    {product.snippet.channelTitle}
                  </h5>
                  </Link>
                </a>
                
               <div>
                {/* <span>
                  {product.description}
                </span> */}
               </div>
               
              </div>
              
            </div>
          ))}
      </ul>
      {/* {task.map((item) => {
        <div className="w-[95%] bg-slate-700 h-96 rounded-2xl ml-4">
          <img
            className="w-full h-[22vh] rounded-2xl hover:rounded-none "
            src=''
            alt=""
          />
          <h1 className="text-white text-start font-semibold px-2 mt-4">
            {item.kind}
          </h1>
          <h1 className="text-white text-start font-semibold px-2 mt-4">
            {item.etag}
          </h1>
        </div>;
      })} */}
    </div>
  );
}
